import { useState, useEffect } from "react";
import Header from "./Header/Header";
import { useSelector, useDispatch } from "react-redux";
import Banner from "./Banner/Banner";
import MainContainer from "./MainContainer/MainContainer";
import FindUsInYourTown from "./FindUsInYourTown/FindUsInYourTown";
import AboutUs from "./AboutUs/AboutUs";
import OurServices from "./OurServices/OurServices";
import Footer from "./Footer/Footer";
import CopyRight from "./CopyRight/CopyRight";
import Gallery from "./Gallery/Gallery";
import CustomerReviews from "./CustomerReviews/CustomerReviews";
import {
  setCitiesInfo,
  setWebConfig,
} from "../../../redux/actions/webConfigActions";
import {
  locationCodeMapSelector,
  serviceAvailableAtCitiesSelector,
} from "../../../redux/selectors/webConfigSelector";
import { fetchBlogs, fetchWebConfig } from "../../../apis/ConfigAPI";
import { setBlogs } from "../../../redux/actions/blogsActions";
import { useSessionManagement } from "../../../tracking/eventTracker";
import { Helmet, HelmetProvider } from "react-helmet-async";
import seoStrings from "../../../seoSrings";
import { resetSelectedTheaterDate } from "../../../redux/actions/bookingActions";
import { Organization } from "schema-dts";
import { helmetJsonLdProp } from "react-schemaorg";
import HowItWorks from "./HowItWorks/HowItWorks";
import HaveDoubts from "./HaveDoubts/HaveDoubts";
import VideoPopup from "./videoPopup";

const Home = () => {
  const dispatch = useDispatch();
  const serviceAvailableAtCities =
    useSelector(serviceAvailableAtCitiesSelector) || [];
  const locationCodeMap = useSelector(locationCodeMapSelector) || {};
  const [error, setError] = useState("");

  // logging and monitoring
  useSessionManagement("HOME");

  useEffect(() => {
    if ((window as any).__PRELOADED_STATE__) {
      const webConfig = (window as any).__PRELOADED_STATE__.webConfig;
      const blogs = (window as any).__PRELOADED_STATE__.blogs;
      if (webConfig) dispatch(setWebConfig(webConfig));
      if (blogs) dispatch(setBlogs(blogs));
    } else {
      dispatch(resetSelectedTheaterDate());
      fetchWebConfig().then(response => {
        dispatch(setWebConfig(response));
      }).catch(error => {
        setError(error);
      });
  
      fetchBlogs().then(response => {
        dispatch(setBlogs(response));
      }).catch(error => {
        setError(error);
      });
    }
  }, []);
  

  useEffect(() => {
    dispatch(resetSelectedTheaterDate());
    fetchWebConfig()
      .then((response) => {
        dispatch(setWebConfig(response));
      })
      .catch((error) => {
        setError(error);
      });

    fetchBlogs()
      .then((response) => {
        dispatch(setBlogs(response));
      })
      .catch((err) => {
        setError(err);
      });
  }, []);

  useEffect(() => {
    let citiesInfo: any = {};
    for (let i = 0; i < serviceAvailableAtCities.length; i++) {
      let cityName = serviceAvailableAtCities[i]?.cityName;
      if (cityName in citiesInfo) {
        citiesInfo[cityName] = [...citiesInfo[cityName].locations];
      } else {
        citiesInfo[cityName] = [...serviceAvailableAtCities[i].locations];
      }
    }

    for (let city in citiesInfo) {
      let locations = citiesInfo[city];
      for (let i = 0; i < locations.length; i++) {
        locations[i] = {
          ...locations[i],
          locationCode: locationCodeMap[locations[i].locationName],
        };
      }
    }
    dispatch(setCitiesInfo({ citiesInfo: citiesInfo }));
  }, [serviceAvailableAtCities, locationCodeMap]);

  return (
    <>
      <HelmetProvider>
        <Helmet 
        script={[
          helmetJsonLdProp<Organization>({
            "@context": "https://schema.org",
            "@type": "Organization",
            url: "https://www.thebingetown.com/",
            name: "The Binge Town",
            description: "The Binge Town | Private Theaters for Celebration Surprises",
            logo: "https://www.thebingetown.com/TBT.jpeg",
            sameAs: [
                "https://www.facebook.com/thebingetown",
                "https://www.twitter.com/thebingetown",
                "https://www.linkedin.com/the-binge-town/"
            ]
          }),
        ]}>
          <title>{seoStrings?.["home"]?.["title"]}</title>
          <meta
            name="description"
            content={seoStrings?.["home"]?.["description"]}
          />
          <meta name="keywords" content={seoStrings["home"]["keyword"]} />
        </Helmet>
      </HelmetProvider>
      <VideoPopup />
      <Header />
      <Banner />
      <MainContainer />
      <FindUsInYourTown />
      <AboutUs />
      <HowItWorks/>
      <CustomerReviews />
      <HaveDoubts/>
      <OurServices />
      <Gallery />
      <Footer />
      <CopyRight />
    </>
  );
};
export default Home;
