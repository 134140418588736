import CopyRight from "../Home/screens/CopyRight/CopyRight";
import Header from "../Home/screens/Header/Header";
import { HiArrowLeft } from "react-icons/hi";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  getBookingDetails,
  getBookingSummary,
  getCouponInfo,
  getExtraNumberOfPeople,
  getFogCount,
  getIsCouponAppliedSuccessfully,
  getPersonalDetails,
  getSelectedLocation,
  getSelectedOccasion,
  getSelectedTheater,
  getSelectedTheaterDate,
  getSelectedTheaterTime,
  getSelectedTimeSlotInd,
  getShortSlotSelected,
} from "../../redux/selectors/bookingSelector";
import { useEffect, useState } from "react";
import useRazorpay from "react-razorpay";
import * as S from "./Agreement.style";
import BookingCancelled from "../../assets/images/BookingCancelled.svg";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import error_icon from "../../assets/images/error_icon.webp";

import { PROD } from "../../endPointsConfig";
import { getUpdateBookingDetail } from "../../redux/selectors/blogsSelector";
import Calls from "../../apis/Calls";
import { useSessionManagement } from "../../tracking/eventTracker";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "16px solid #fffff",
  borderRadius: 4,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  fontFamily: "Montserrat",
};

const Agreement = () => {
  let { id } = useParams();
  const [Razorpay] = useRazorpay();
  const navigate = useNavigate();
  const [acceptedTermsCondition, setAcceptedTermsConditions] = useState(false);
  const selectedTheaterTime = useSelector(getSelectedTheaterTime);
  const personalDetails = useSelector(getPersonalDetails);
  const selectedLocation = useSelector(getSelectedLocation);
  const selectedTheater = useSelector(getSelectedTheater);
  const bookingDetails = useSelector(getBookingDetails);
  const selectedDate = useSelector(getSelectedTheaterDate);
  const selectedOccasion = useSelector(getSelectedOccasion);
  const selectedTimeSlotInd = useSelector(getSelectedTimeSlotInd);
  const bookingSummary = useSelector(getBookingSummary);
  const extraNumberOfPeople = useSelector(getExtraNumberOfPeople);
  //const fogCount = useSelector(getFogCount);
  const shortSlotSelected = useSelector(getShortSlotSelected);
  const coupon = useSelector(getCouponInfo);
  const isCouponApplied = useSelector(getIsCouponAppliedSuccessfully);
  // const updateBooking = useSelector(getUpdateBookingDetail) || {};
  const isUpdateBooking = id ? true : false;

  const [open, setOpen] = useState(false);

  const { personName = "", partnerName = "" } = personalDetails || {};
  const {
    theatre,
    code: theaterCode,
    decorCost,
    avgPerson,
    location
  } = selectedTheater || {};

  const { bookingName, whatsappNumber, emailAddress } = bookingDetails || {};
  const { year, month, day } = selectedDate || {};
  const { name: occasionName } = selectedOccasion || {};
  const { locationName } = selectedLocation || {};
  const {
    cake = [],
    addOns = [],
    theaterCost: { isDecorCostAdded, numberOfPeople, price: theatercost },
    subTotal,
    advancePayment,
  } = bookingSummary || {};
  const { code: couponCode, price: couponPrice } = coupon || {};
  const [cakeString, setCakeString] = useState<String>("");
  const [addOnsString, setAddOnsString] = useState<String>("");
  const [extraPrice, setExtraPrice] = useState<Number>(0);
  const [error, setError] = useState("") || "";
  const [boookingErrorOpen, setBoookingErrorOpen] = useState(false);
  const [boookingUpdatedOpen, setBoookingUpdateOpen] = useState(false);
  const [updateBookingError, setUpdateBookingError] = useState(false);

  const numOfPeople = String(extraNumberOfPeople + avgPerson);
  const totalPrice = isCouponApplied ? subTotal - couponPrice : subTotal;
  const isMweb = useMediaQuery("(max-width:786px)");

  const handleClose = () => {
    setOpen(false);
  };

  const cakeToString = (cake: any) => {
    let str = "";
    for (let i = 0; i < cake.length; i++) {
      str += `${cake[i]?.name} (${cake[i].price})${
        i === cake.length - 1 ? "" : ","
      }`;
    }
    return str;
  };

  const addOnsToString = (addOns: any) => {
    let str = "";
    for (let i = 0; i < addOns.length; i++) {
      if(addOns[i].name.includes("Fog Entry")){
        str += `${addOns[i]?.name} (Rs ${addOns[i].price})${
          i === addOns.length - 1 ? "" : ","
        }`;
        continue;
      }
      str += `${addOns[i]?.name} (Rs ${addOns[i].price})${
        i === addOns.length - 1 ? "" : ","
      }`;
    }
    return str;
  };

  useEffect(() => {
    let cakeStr = cakeToString(cake);
    setCakeString(cakeStr);

    let addOnsStr = addOnsToString(addOns);
    setAddOnsString(addOnsStr);

    let extraPrice = 0;
    for (let i = 0; i < cake.length; i++) {
      extraPrice += cake[i]?.price;
    }
    for (let i = 0; i < addOns.length; i++) {
      // if(addOns[i].name.includes("Fog Entry")){
      //   extraPrice += (fogCount*addOns[i]?.price);
      // }else{
      //console.log("Calculating .. ", addOns[i]?.price, extraPrice);
        extraPrice += addOns[i]?.price;
      //}
    }
    setExtraPrice(extraPrice);
  }, []);

  function postBooking(bookObj: any): any {
    return new Promise((resolve, reject) => {
      fetch(`${PROD}/postBooking`, {
        method: "POST",
        body: JSON.stringify(bookObj),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (response.status != 200) {
            return resolve(false);
          }
          return resolve(true);
        })
        .catch((err) => {
          return resolve(false);
        });
    });
  }
  let finalBookObj: any = {};

  let createBooking = async (paymentDetails: any, orderId: any) => {
    finalBookObj["payment_id"] =
      paymentDetails && paymentDetails.razorpay_payment_id
        ? paymentDetails.razorpay_payment_id
        : "none";
    finalBookObj["order_id"] =
      paymentDetails && paymentDetails.razorpay_order_id
        ? paymentDetails.razorpay_order_id
        : orderId
        ? orderId
        : "none";
    finalBookObj["signature"] =
      paymentDetails && paymentDetails.razorpay_signature
        ? paymentDetails.razorpay_signature
        : "none";

    let resp = await postBooking(finalBookObj);
    if (resp) {
      // setFinalStatus(true);
      return true;
    } else {
      // setFinalStatus(false);
      return false;
    }
    // setFinalMsgState(false);
    // setCallState(false);
    // setFinalPage(true);
  };

  const handlePayment = () => {
    if (isUpdateBooking) {
      setOpen(true);
    } else {
      finalBookObj = {
        name: bookingName,
        date: `${year}-${month}-${day}`,
        time: selectedTheaterTime,
        location: location,
        theatre: theatre,
        code: `${theaterCode}_${year}${month}${day}_${selectedTimeSlotInd}`,
        email: emailAddress,
        totalPrice: isDecorCostAdded ? totalPrice : theatercost,
        status: "Not Done",
        phoneNum: whatsappNumber,
        advancePaid: advancePayment,
        numOfPeople: numOfPeople,
        decorPrice: isDecorCostAdded ? decorCost : 0,
        decorType: isDecorCostAdded ? occasionName : "None",
        addOns: isDecorCostAdded ? addOnsString : "",
        extraPrice: isDecorCostAdded ? extraPrice : 0,
        day: 1, //
        duration: shortSlotSelected ? 1.5 : 3,
        theatrePrice: theatercost,
        cake: isDecorCostAdded ? cakeString : "",
        names: isDecorCostAdded ? `${personName} , ${partnerName}` : "",
        age: 0,
        coupon: couponCode,
        couponValue: couponPrice,
        utm_tags: "",
      };
      if(!year || !month || !day){
        alert(`Uknown date selected, please select again`);
        navigate('/');
        return;
      }
      let utm_data = localStorage.getItem('tbt_njkwe_sessionData');
      if(utm_data){
        finalBookObj.utm_tags = utm_data;
        let utmData:any = JSON.parse(utm_data);
        if(utmData){
          utmData['stage'] = "PAYMENT";
          Calls.sendUserData('UtmData', JSON.stringify(utmData));  
        }          
      }
      // createBooking({}, "testId");
      // return;
      fetch(`${PROD}/getOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(finalBookObj),
      })
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if (response?.e) {
            setError(response?.e);
            setBoookingErrorOpen(true);
            return;
          }

          const handlePaymentRazorpay = async (
            orderId: any,
            amt: any,
            userDetails: any
          ) => {
            const options: any = {
              key: "rzp_live_GQXH6iqba8bndz", // Enter the Key ID generated from the Dashboard
              amount: amt?.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
              currency: "INR",
              name: "Bcks Franchise Llp",
              description: "Advance payment for Binge Town Slot",
              image: "https://example.com/your_logo",
              order_id: orderId, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
              handler: async (response: {
                razorpay_payment_id: any;
                razorpay_order_id: any;
                razorpay_signature: any;
              }) => {
                let resp = await createBooking(response, orderId);
                if (resp) {
                  window.location.replace(
                    "https://thebingetown.com/successBooking"
                  );
                } else {
                  window.location.replace(
                    "https://thebingetown.com/failedBooking"
                  );
                }
              },
              modal: {
                escape: false,
                ondismiss: () => {
                  console.log("opopopopopopop");
                },
              },
              prefill: userDetails,
              notes: {
                // code: `${finalBookObj.code}_${finalBookObj.date}`,
              },
              theme: {
                color: "#3399cc",
              },
            };
            window.scrollTo(0, 0);
            const rzp1 = new Razorpay(options);

            rzp1.on(
              "payment.failed",
              function (response: {
                error: {
                  code: any;
                  description: any;
                  source: any;
                  step: any;
                  reason: any;
                  metadata: { order_id: any; payment_id: any };
                };
              }) {
                window.location.replace(
                  "https://thebingetown.com/failedBooking"
                );
              }
            );
            rzp1.open();
          };
          handlePaymentRazorpay(response?.id, response?.amt, {
            name: finalBookObj.name,
            email: finalBookObj.email,
            contact: finalBookObj.phoneNum,
          });
        })
        .catch((err) => {});
    }
  };

  const handleTermsConditions = (e: any) => {
    setAcceptedTermsConditions(!acceptedTermsCondition);
  };

  const handleUpdateBooking = () => {
    fetch(`${PROD}/updateBooking`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        name: bookingName,
        email: emailAddress,
        status: "Not Done",
        phoneNum: whatsappNumber,
        advancePaid: advancePayment,
        decorType: isDecorCostAdded ? String(occasionName) : "None",
        numOfPeople: String(numberOfPeople),
        decorPrice: isDecorCostAdded ? decorCost : 0,

        addOns: isDecorCostAdded ? addOnsString : "",
        extraPrice: extraPrice,
        theatrePrice: theatercost,
        cake: isDecorCostAdded ? cakeString : "",
        names: `${personName} , ${partnerName}`,
        totalPrice: totalPrice,
      }),
    })
      .then((response) => {
        if (response?.status === 200) {
          handleBookingUpdateModal();
        } else {
          handleBookingUpdateModal();
          setUpdateBookingError(true);
        }
      })
      .then((response) => {})
      .catch((err) => {
        handleBookingUpdateModal();
        setUpdateBookingError(true);
      });
  };

  const handleBookingUpdateModal = () => {
    setBoookingUpdateOpen(true);
  };

  const goBackToHome = () => {
    navigate("/");
  };

  return (
    <>
      <Header />

      <S.AgreementContainer>
        <S.BackButtonContainer onClick={() => navigate(-1)}>
          <HiArrowLeft size={24} />
          <S.BackButtonTxt>Back</S.BackButtonTxt>
        </S.BackButtonContainer>
        <S.TermsConditionsContainer>
          <S.TermsConditionsTitle>Terms & Conditions</S.TermsConditionsTitle>
          <S.TermsConditions>
            <S.TermsCondition>
              We do NOT provide any movie/OTT accounts. We will do the setups
              using your OTT accounts/downloaded content.
            </S.TermsCondition>
            <S.TermsCondition>
              Smoking/Drinking is NOT allowed inside the theater.
            </S.TermsCondition>
            <S.TermsCondition>
              Any DAMAGE caused to theater, including decorative materials like
              ballons, lights etc will have to be reimbursed.
            </S.TermsCondition>
            <S.TermsCondition>
              Guests are requested to maintain CLEANLINESS inside the theater.
            </S.TermsCondition>
            <S.TermsCondition>
              Party poppers/Snow sprays/Cold Fire, and any other similar items
              are strictly prohibited inside the theater.
            </S.TermsCondition>
            <S.TermsCondition>
              Carrying AADHAAR CARD is mandatory. It will be scanned during
              entry.
            </S.TermsCondition>
            <S.TermsCondition>
              Couples under 18 years of age are not allowed to book the theatre
            </S.TermsCondition>
            <S.TermsCondition>
              Pets are strictly not allowed inside the theatre
            </S.TermsCondition>
            <S.TermsCondition>
              We collect an advance amount of RS. 750 plus convenience fee to
              book the slot.
            </S.TermsCondition>
          </S.TermsConditions>
          <S.RefundPolicyHeading>Refund policy</S.RefundPolicyHeading>
          <S.RefundPolicyTxt>
            Advance amount is fully refundable if slot is cancelled at least 72
            hrs before the slot time. If your slot is less than 72 hrs away from
            time of payment then advance is non-refundable.
          </S.RefundPolicyTxt>
          <S.Actions>
            <S.Checkbox
              type="checkbox"
              required
              checked={acceptedTermsCondition}
              onChange={handleTermsConditions}
            ></S.Checkbox>
            <S.AgreeBox>I agree to the above terms & conditions.</S.AgreeBox>
          </S.Actions>
          <S.ButtonViewContainer>
            <S.ButtonView
              onClick={handlePayment}
              disabled={!acceptedTermsCondition}
            >
              {!isUpdateBooking ? "Pay and book" : "Update changes"}
            </S.ButtonView>
          </S.ButtonViewContainer>
        </S.TermsConditionsContainer>
        <S.ExtraSpace></S.ExtraSpace>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: isMweb ? 270 : 400 }}>
            <S.ModalContainer>
              <S.ModalHeading>
                <S.ModalHeadingTxt>Confirm your changes</S.ModalHeadingTxt>
                <IoCloseOutline size={24} onClick={handleClose} />
              </S.ModalHeading>
              <S.ModalDescription>
                Click on Confirm to update your booking details
              </S.ModalDescription>
              <S.ButtonView onClick={handleUpdateBooking}>Confirm</S.ButtonView>
            </S.ModalContainer>
          </Box>
        </Modal>
        <Modal
          open={boookingUpdatedOpen}
          onClose={goBackToHome}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: isMweb ? 270 : 400 }}>
            <S.ModalContainer>
              <S.ModalHeading1>
                <img
                  alt="bookingCancelledIcon"
                  title="bookingCancelledIcon"
                  src={updateBookingError ? error_icon : BookingCancelled}
                  style={{ width: "40px", height: "40px" }}
                />
              </S.ModalHeading1>
              <S.ModalSubTxt>
                {updateBookingError
                  ? "Failed to update booking details. Please try later."
                  : "Booking updated succefully!"}
              </S.ModalSubTxt>
              <S.ButtonView onClick={goBackToHome}>Go to Home</S.ButtonView>
            </S.ModalContainer>
          </Box>
        </Modal>
        <Modal
          open={boookingErrorOpen}
          onClose={goBackToHome}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: isMweb ? 270 : 400 }}>
            <S.ModalContainer>
              <S.ModalHeading1>
                <img
                  alt="bookingCancelledIcon"
                  title="bookingCancelledIcon"
                  src={error && error_icon}
                  style={{ width: "40px", height: "40px" }}
                />
              </S.ModalHeading1>
              <S.ModalSubTxt>
                {error && "Session expired. Please try later."}
              </S.ModalSubTxt>
              <S.ButtonView onClick={goBackToHome}>Go to Home</S.ButtonView>
            </S.ModalContainer>
          </Box>
        </Modal>
      </S.AgreementContainer>

      <S.FooterContainer>
        <CopyRight />
      </S.FooterContainer>
    </>
  );
};
export default Agreement;
