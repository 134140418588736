import Header from "../../../Home/screens/Header/Header";
import LeftViewSection from "./components/LeftViewSection/LeftViewSection";
import CopyRight from "../../../Home/screens/CopyRight/CopyRight";
import BookingSummary from "./components/BookingSummery/BookingSummary";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getActiveDecorations,
  getActiveGifts,
  getActiveServices,
  getBookingDetails,
  getBookingDetailsFormValid,
  getCouponInfo,
  getIsCouponAppliedSuccessfully,
  getSelectedTheater,
  getShortSlotSelected,
  getSubTotalAmount,
  getExtraNumberOfPeople,
  getBookingSummaryTheaterCost,
  getSelectedTheaterDate,
  getSelectedTheaterTime,
} from "../../../../redux/selectors/bookingSelector";
import { useEffect, useState } from "react";
import {
  setActiveCakes,
  setActiveDecorations,
  setActiveGifts,
  setActiveServices,
  setBookingSummaryAdvance,
  setBookingSummaryCoupon,
  setBookingSummaryTheaterCost,
  setCouponAppliedSuccessfully,
  setSelectedTheater,
  setSelectedTheaterDate,
  setSelectedTheaterTime,
  setSelectedTimeSlotInd,
  setShortSlotSelected,
  setSubtotalAmount,
} from "../../../../redux/actions/bookingActions";
import * as S from "./BookingOverview.style";
import { PROD } from "../../../../endPointsConfig";
import {
  getUpdateBookingDetail,
  getUpdateBookingDetails,
} from "../../../../redux/selectors/blogsSelector";
import { setUpdateBookingDetail } from "../../../../redux/actions/blogsActions";
import {store} from "../../../../store";
import Calls from "../../../../apis/Calls";

const BookingOverview = () => {
  const navigate = useNavigate();
  let { theaterCode, id } = useParams();
  const location = useLocation();
  const { search } = location;
  const dispatch = useDispatch();

  const selectedTheater = useSelector(getSelectedTheater) || {};
  const isShortSlotSelected = useSelector(getShortSlotSelected);
  const bookingDetailsFormValid = useSelector(getBookingDetailsFormValid);
  const bookingDetails = useSelector(getBookingDetails);
  const allUpdateBookingDetails = useSelector(getUpdateBookingDetails) || [];
  const updateBookingDetail = useSelector(getUpdateBookingDetail) || {};
  const extraNumberOfPeople = useSelector(getExtraNumberOfPeople);
  const selectedTheaterTime = useSelector(getSelectedTheaterTime);
  const selectedDate = useSelector(getSelectedTheaterDate);

  const bookingSummaryTheaterCost = useSelector(getBookingSummaryTheaterCost);

  const { isDecorationSelected, bookingName, emailAddress, whatsappNumber } =
    bookingDetails || {};
  useEffect(() => {
    const bookingDetails = allUpdateBookingDetails.filter(
      (bookingDetails: any) => bookingDetails.id === id
    );

    dispatch(setUpdateBookingDetail(bookingDetails[0]));

    theaterCode =
      bookingDetails[0]?.code?.split("_")?.slice(0, 2)?.join("_") || "";
  }, [id]);

  const {
    name: theaterName,
    location: theaterLocation,
    maxPerson,
    avgPerson,
    theatreCost,
    shortSlotPrice,
    decorCost,
    advance,
    costPerExtraPerson,
    costPerExtraPersonShort,
  } = selectedTheater || {};

  const [showBookingSummary, setShowBookingSummary] = useState<Boolean>(false);
  const [error, setError] = useState("");
  const activeDecorationsSelector = useSelector(getActiveDecorations);
  const activeGiftsSelector = useSelector(getActiveGifts);
  const activeServicesSelector = useSelector(getActiveServices);
  const isCouponApplied = useSelector(getIsCouponAppliedSuccessfully);
  const subTotalAmount = useSelector(getSubTotalAmount) || 0;
  const { name, price, isDecorCostAdded, numberOfPeople } =
    bookingSummaryTheaterCost || {};
  const coupon = useSelector(getCouponInfo);
  const { code: couponCode, price: couponPrice } = coupon || {};

  const isUpdateBooking = Object.keys(updateBookingDetail)?.length > 0;

  let updateBookingFlag = isUpdateBooking ? "updateCall" : "";

  const [activeDecoration, setActiveDecoration] = useState<String[]>(
    activeDecorationsSelector || []
  );
  const [activeGift, setActiveGift] = useState<String[]>(
    activeGiftsSelector || []
  );
  const [activeService, setActiveService] = useState<String[]>(
    activeServicesSelector || []
  );

  useEffect(() => {
    const cakeNames = updateBookingDetail?.cake
      ?.split(",")
      ?.map((cake: any) => {
        return cake?.split("(")[0]?.trim();
      });
    dispatch(setActiveCakes(cakeNames));
  }, [updateBookingDetail.cake]);
  useEffect(() => {
    const cakeNames = updateBookingDetail?.addOns
      ?.split(",")
      ?.map((cake: any) => {
        return cake?.split("(")[0]?.trim();
      });
    dispatch(setActiveDecorations(cakeNames));
    dispatch(setActiveGifts(cakeNames));
    dispatch(setActiveServices(cakeNames));
  }, [updateBookingDetail.addOns]);

  useEffect(() => {
    dispatch(setActiveDecorations(activeDecoration));
  }, [activeDecoration]);

  useEffect(()=>{
    if(!selectedDate && search && search.includes('date')){
      let dateStr = search.split('=')[1];
      let [year,month,day] = dateStr.split('_');
      let date = new Date(Number.parseInt(year), Number.parseInt(month)-1, Number.parseInt(day));
      store.dispatch(
        setSelectedTheaterDate({
          year: year,
          month: month,
          day: day,
          fullDate: date,
        })
      );
    }
  }, [])

  useEffect(() => {
    dispatch(setActiveGifts(activeGift));
  }, [activeGift]);

  useEffect(() => {
    dispatch(setActiveServices(activeService));
  }, [activeService]);

  useEffect(() => {
    let cost = isShortSlotSelected ? shortSlotPrice : theatreCost;
    let extraPersonCost = isShortSlotSelected
      ? costPerExtraPersonShort
      : costPerExtraPerson;

    dispatch(
      setBookingSummaryTheaterCost({
        name: `Theater `,
        price: cost + extraNumberOfPeople * extraPersonCost,
        isDecorCostAdded: true,
        decorCost: decorCost,
        numberOfPeople: numberOfPeople || 2,
      })
    );
    dispatch(setBookingSummaryAdvance(advance));
    store.dispatch(setSubtotalAmount());

    if(isUpdateBooking && updateBookingDetail.createdOn < "2024-07-03"){
      console.log("Booking is older ", updateBookingDetail);
      updateBookingFlag = "isUpdate";
    }

    fetch(`${PROD}/webConfigv2?theaterCode=${theaterCode}&newWeb=newWeb3&isUpdate=${updateBookingFlag}`)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response && response?.length > 0)
          dispatch(setSelectedTheater(response[0]));
      })
      .catch((error: any) => {
        setError(error);
      });
  }, []);

  useEffect(() => {
    if (!updateBookingDetail || Object.keys(updateBookingDetail).length === 0)
      return;
    let cost = isShortSlotSelected ? shortSlotPrice : theatreCost;
    if (
      "theatrePrice" in updateBookingDetail ||
      "decorPrice" in updateBookingDetail ||
      "code" in updateBookingDetail
    ) {
      dispatch(
        setBookingSummaryTheaterCost({
          name: `Theater `,
          price: updateBookingDetail?.theatrePrice,
          isDecorCostAdded: updateBookingDetail?.decorPrice > 0,
          decorCost: updateBookingDetail?.decorPrice || 0,
          numberOfPeople: updateBookingDetail?.numberPeople,
        })
      );
      dispatch(setBookingSummaryAdvance(updateBookingDetail?.advancePaid));
      store.dispatch(setSubtotalAmount());
      let selectedUpdatedDate = new Date(updateBookingDetail?.date);

      dispatch(
        setSelectedTheaterDate({
          year: selectedUpdatedDate.getFullYear(),
          month: selectedUpdatedDate.getMonth() + 1,
          day: selectedUpdatedDate.getDate(),
          fullDate: selectedUpdatedDate,
        })
      );
      let timeInd = updateBookingDetail?.code?.split("_")?.pop();
      dispatch(setSelectedTimeSlotInd(timeInd));
      dispatch(
        setBookingSummaryCoupon({
          code: updateBookingDetail?.coupon,
          price: updateBookingDetail?.couponValue,
        })
      );
      if (updateBookingDetail?.coupon && updateBookingDetail?.coupon !== "") {
        dispatch(setCouponAppliedSuccessfully(true));
      }

      dispatch(setSelectedTheaterTime(updateBookingDetail?.time));
      if (updateBookingDetail?.duration !== 3) {
        dispatch(setShortSlotSelected(true));
      }
    } else {
      dispatch(
        setBookingSummaryTheaterCost({
          name: `Theater `,
          price: cost,
          isDecorCostAdded: true,
          decorCost: decorCost,
          numberOfPeople: avgPerson,
        })
      );
    }
  }, [updateBookingDetail]);

  const handleNext = () => {
    const phonePattern = /^\d{10}$/;
    if (!phonePattern.test(whatsappNumber)) {
      alert("Please enter a valid 10-digit WhatsApp number.");
      return;
    }
  
    // Validate Email
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    if (!emailPattern.test(emailAddress)) {
      alert("Please enter a valid email address.");
      return;
    }
    if (bookingDetailsFormValid && !isDecorationSelected) {
      Calls.sendUserData("UserData", {
        name: bookingName,
        date: `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`.toString(),
        time: selectedTheaterTime,
        location: theaterLocation,
        theatre: theaterName,
        email: emailAddress,
        phoneNum: whatsappNumber,
        status: "FORM_FILL",
      });

      if (isUpdateBooking)
        navigate(`/terms_conditions_agreement/${updateBookingDetail?.id}`);
      else navigate("/terms_conditions_agreement");
    }
    if (bookingDetailsFormValid && isDecorationSelected) {
      Calls.sendUserData("UserData", {
        name: bookingName,
        date: `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`.toString(),
        time: selectedTheaterTime,
        location: theaterLocation,
        theatre: theaterName,
        email: emailAddress,
        phoneNum: whatsappNumber,
        status: "FORM_FILL",
      });

      navigate("choose_occasion");
    }
  };

  const handleShowBookingSummary = () => {
    setShowBookingSummary(!showBookingSummary);
  };

  return (
    <>
      <Header />
      <S.BookingOverviewContainer>
        <S.LeftViewSectionContainer>
          <LeftViewSection></LeftViewSection>
          <S.MwebFooterContainer>
            <S.PriceBreakupSummaryContainer>
              <S.PriceBreakupHeader>
                <S.PriceBreakupTotal>Total</S.PriceBreakupTotal>
                <S.PriceBreakupPrice>
                  &#8377;
                  {isCouponApplied
                    ? subTotalAmount - couponPrice
                    : subTotalAmount}
                </S.PriceBreakupPrice>
              </S.PriceBreakupHeader>
              <S.PriceBreakup>
                <S.BookingSummaryHeading>
                  <S.BookingSummaryTxt> Booking summary</S.BookingSummaryTxt>
                  {showBookingSummary ? (
                    <MdOutlineKeyboardArrowUp
                      size={24}
                      onClick={handleShowBookingSummary}
                    />
                  ) : (
                    <MdOutlineKeyboardArrowDown
                      size={24}
                      onClick={handleShowBookingSummary}
                    />
                  )}
                </S.BookingSummaryHeading>
              </S.PriceBreakup>
              <S.PriceBreakupDetails>
                {showBookingSummary ? <BookingSummary /> : null}
              </S.PriceBreakupDetails>
            </S.PriceBreakupSummaryContainer>
            <S.BookNowBtn
              onClick={handleNext}
              type="button"
              disabled={!bookingDetailsFormValid}
            >
              Next step
            </S.BookNowBtn>
          </S.MwebFooterContainer>
        </S.LeftViewSectionContainer>
        <S.RightViewSectionContainer>
          <BookingSummary />
        </S.RightViewSectionContainer>
      </S.BookingOverviewContainer>
      <CopyRight />
    </>
  );
};
export default BookingOverview;
