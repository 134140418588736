import { PROD } from "../endPointsConfig";

export const fetchWebConfig = () => {
  return new Promise((resolve, reject) => {
    fetch(`${PROD}/webConfigv2?newWeb=newWeb3`)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {});
  });
};

export const fetchBlogs = () => {
  return new Promise((resolve, reject) => {
    fetch(`${PROD}/getBlogs`)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
