import Home from "./features/Home/screens/Home";
import { Routes, Route, Navigate } from "react-router-dom";
import OurServicePage from "./features/Pages/OurServicePage/OurServicePage";
import AboutUsPage from "./features/Pages/AboutUsPage/AboutUsPage";
import GalleryPage from "./features/Pages/GalleryPage/GalleryPage";
import ContactUsPage from "./features/Pages/ContactUsPage/ContactUsPage";
import RefundPolicy from "./features/Pages/RefundPolicy/RefundPolicy";
import TermsAndConditions from "./features/Pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./features/Pages/PrivacyPolicy/PrivacyPolicy";
import WaitListForm from "./features/WaitListForm/WaitListForm";
import BlogsPage from "./features/Pages/Blogs/Blogs";
import Article from "./features/Pages/Article/Article";
import FAQs from "./features/Pages/FAQs/FAQs";
import Login from "./features/Login/Login";
import TheaterDetails from "./features/Booking/screens/TheatersDetails/TheaterDetails";
import RightViewSection from "./features/Booking/screens/TheatersDetails/components/RightViewSection/RightViewSection";
import BookingOverview from "./features/Booking/screens/BookingOverview/BookingOverview";
import Occasion from "./features/Booking/screens/BookingOverview/components/Occasion/Occasion";
import Agreement from "./features/Agreement/Agreement";
import CakeSelection from "./features/Booking/screens/BookingOverview/components/CakeSelection/CakeSelection";
import DecorationSelection from "./features/Booking/screens/BookingOverview/components/DecorationSelection/DecorationSelection";
import OTPVerification from "./features/Login/OTPVerification";
import MyBooking from "./features/Booking/screens/MyBooking/MyBooking";
import LocationSelection from "./features/Booking/screens/LocationSelection/LocationSelection";
import Pricing from "./features/Pages/Pricing/Pricing";
import PaymentFailed from "./features/Booking/common/PaymentFailed";
import PaymentSuccess from "./features/Booking/common/PaymentSuccess";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import seoStrings from "./seoSrings";
import "./App.css";
import PageNotFound from "./PageNotFound";
import EarlyBirdForm from "./features/EarlyBird/EarlyBirdForm";
import Layout from "./layout";

// const TRACKING_ID = "G-SR59D4ESPJ";
// ReactGA.initialize(TRACKING_ID);
ReactPixel.init("2044266849142759");

function App() {
  ReactGA.pageview(window.location.pathname);
  ReactPixel.pageView(); // For tracking page view

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/service-worker.js") // Path to your Service Worker file
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    }
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{seoStrings["home"]["title"]}</title>
          <meta
            name="description"
            content={seoStrings["home"]["description"]}
          />
          <meta name="theme-color" content="#ccc" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          ></meta>
          <link rel="stylesheet" href="./bootstrap.css"></link>
          <meta name="keywords" content={seoStrings["home"]["keyword"]} />
          <meta name="title" content={seoStrings["home"]["title"]} />
          <link rel="canonical" href="https://thebingetown.com/" />
          {/* Facebook tags */}
          <meta property="og:site_name" content="TheBingeTown" />
          <meta property="og:type" content="Website" />
          <meta property="og:title" content={seoStrings["home"]["title"]} />
          <meta
            property="og:description"
            content={seoStrings["home"]["description"]}
          />
          <meta property="og:url" content="https://www.facebook.com/thebingetown" />
          <meta
            property="og:image"
            key="og:image"
            content={
              "https://storage.googleapis.com/tbt-pics/images/Homepage/bangalore_1.png"
            }
          />
          {/* End Facebook tags */}
          {/* Twitter tags */}
          <meta name="twitter:url" content={"https://twitter.com/thebingetown"} />
          <meta name="twitter:creator" content={"TheBingeTown"} />
          <meta name="twitter:card" content={seoStrings["home"]["keyword"]} />
          <meta name="twitter:title" content={seoStrings['home']['title']} />
          <meta name="twitter:description" content={seoStrings['home']['description']} />
          <meta name="twitter:image" content={"https://storage.googleapis.com/tbt-pics/images/Homepage/bangalore_1.png"} />
          {/* End Twitter tags */}
          {/* Youtube tags */}
          <meta name="youtube:url" content={"https://www.youtube.com/channel/UCkUByxBsPjV_RCbOQx-v_IQ"} />
          <meta name="youtube:creator" content={"TheBingeTown"} />
          <meta name="youtube:card" content={seoStrings["home"]["keyword"]} />
          <meta name="youtube:title" content={seoStrings['home']['title']} />
          <meta name="youtube:description" content={seoStrings['home']['description']} />
          <meta name="youtube:image" content={"https://storage.googleapis.com/tbt-pics/images/Homepage/bangalore_1.png"} />
          {/* End youtube tags */}
          {/* Instagram tags */}
          <meta name="instagram:url" content={"https://www.instagram.com/bingetown_in/"} />
          <meta name="instagram:creator" content={"TheBingeTown"} />
          <meta name="instagram:card" content={seoStrings["home"]["keyword"]} />
          <meta name="instagram:title" content={seoStrings['home']['title']} />
          <meta name="instagram:description" content={seoStrings['home']['description']} />
          <meta name="instagram:image" content={"https://storage.googleapis.com/tbt-pics/images/Homepage/bangalore_1.png"} />
          {/* End Instagram tags */}
           {/* Linkedin tags */}
           <meta name="linkedin:url" content={"https://www.linkedin.com/company/the-binge-town/"} />
          <meta name="linkedin:creator" content={"TheBingeTown"} />
          <meta name="linkedin:card" content={seoStrings["home"]["keyword"]} />
          <meta name="linkedin:title" content={seoStrings['home']['title']} />
          <meta name="linkedin:description" content={seoStrings['home']['description']} />
          <meta name="linkedin:image" content={"https://storage.googleapis.com/tbt-pics/images/Homepage/bangalore_1.png"} />
          {/* End linkedin tags */}
        </Helmet>
      </HelmetProvider>
      <Layout>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/our_services" element={<OurServicePage />}></Route>
        <Route path="/about_us" element={<AboutUsPage />}></Route>
        <Route path="/my_bookings" element={<MyBooking />}></Route>
        <Route path="/gallery" element={<GalleryPage />}></Route>
        <Route path="/pricing" element={<Pricing />}></Route>
        <Route path="/contact_us" element={<ContactUsPage />}></Route>

        <Route path="/join_waitlist" element={<WaitListForm />}></Route>

        {/* <Route path="/early_bird" element={<EarlyBirdForm />}></Route> */}

        <Route path="/blogs" element={<BlogsPage />}></Route>
        <Route path="/faqs" element={<FAQs />}></Route>

        <Route path="/refund_policy" element={<RefundPolicy />}></Route>
        <Route
          path="/terms_conditions"
          element={<TermsAndConditions />}
        ></Route>
        <Route path="/privacy_policy" element={<PrivacyPolicy />}></Route>

        <Route path="/blogs/:blogId/*" element={<Article />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route
          path="/login/otp_verification/:bookingId"
          element={<OTPVerification />}
        ></Route>

        <Route path="/book_now" element={<LocationSelection />}></Route>
        <Route
          path="/book_now/private-movie-theaters/:locationCode"
          element={<TheaterDetails />}
        ></Route>
        {/* <Route
        path="/book_now/private-movie-theaters"
        element={<TheaterDetails />}
      ></Route> */}

        <Route
          path="/book_now/mweb-private-movie-theaters/:locationCode"
          element={<RightViewSection />}
        ></Route>
        <Route
          path="/book_now/private-movie-theaters/:locationCode/booking_overview/:theaterCode"
          element={<BookingOverview />}
        ></Route>
        <Route
          path="/booking_overview/:id"
          element={<BookingOverview />}
        ></Route>
        <Route
          path="/book_now/mweb-private-movie-theaters/:locationCode/booking_overview/:theaterCode"
          element={<BookingOverview />}
        ></Route>
        <Route
          path="/book_now/private-movie-theaters/:locationCode/booking_overview/:theaterCode/choose_occasion"
          element={<Occasion />}
        ></Route>
        <Route
          path="/booking_overview/:id/choose_occasion"
          element={<Occasion />}
        ></Route>
        <Route
          path="/book_now/mweb-private-movie-theaters/:locationCode/booking_overview/:theaterCode/choose_occasion"
          element={<Occasion />}
        ></Route>

        <Route
          path="/book_now/private-movie-theaters/:locationCode/booking_overview/:theaterCode/choose_occasion/choose_cake"
          element={<CakeSelection />}
        ></Route>
        <Route
          path="/booking_overview/:id/choose_occasion/choose_cake"
          element={<CakeSelection />}
        ></Route>
        <Route
          path="/book_now/mweb-private-movie-theaters/:locationCode/booking_overview/:theaterCode/choose_occasion/choose_cake"
          element={<CakeSelection />}
        ></Route>

        <Route
          path="/book_now/private-movie-theaters/:locationCode/booking_overview/:theaterCode/choose_occasion/choose_cake/choose_decoration"
          element={<DecorationSelection />}
        ></Route>
        <Route
          path="/booking_overview/:id/choose_occasion/choose_cake/choose_decoration"
          element={<DecorationSelection />}
        ></Route>
        <Route
          path="/book_now/mweb-private-movie-theaters/:locationCode/booking_overview/:theaterCode/choose_occasion/choose_cake/choose_decoration"
          element={<DecorationSelection />}
        ></Route>
        <Route
          path="/terms_conditions_agreement"
          element={<Agreement />}
        ></Route>
        <Route
          path="/terms_conditions_agreement/:id"
          element={<Agreement />}
        ></Route>

        <Route path="/failedBooking" element={<PaymentFailed />}></Route>
        <Route path="/successBooking" element={<PaymentSuccess />}></Route>
        <Route path="/private-theaters-booking" element={<LocationSelection />}></Route>
        <Route
          path="private-theaters-in-delhi/:location/booking_overview/:theaterCode"
          element={<BookingOverview />}
        ></Route>
         {["bangalore", "hyderabad", "delhi"].map(path => (
          <Route
            key={`/private-theaters-in-${path}/:location`}
            path={`/private-theaters-in-${path}/:location`}
            element={<TheaterDetails/>}
          />
        ))}
        {["bangalore", "hyderabad", "delhi"].map(path => (
          <Route
            key={`mweb-private-theaters-in-${path}/:location`}
            path={`mweb-private-theaters-in-${path}/:location`}
            element={<RightViewSection/>}
          />
        ))}
        {["bangalore", "hyderabad", "delhi"].map(path => (
          <Route
            key={`private-theaters-in-${path}/:location/booking_overview/:theaterCode`}
            path={`private-theaters-in-${path}/:location/booking_overview/:theaterCode`}
            element={<BookingOverview/>}
          />
        ))}
        {["bangalore", "hyderabad", "delhi"].map(path => (
          <Route
            key={`mweb-private-theaters-in-${path}/:location/booking_overview/:theaterCode`}
            path={`mweb-private-theaters-in-${path}/:location/booking_overview/:theaterCode`}
            element={<BookingOverview/>}
          />
        ))}
        {["bangalore", "hyderabad", "delhi"].map(path => (
          <Route
            key={`private-theaters-in-${path}/:location/booking_overview/:theaterCode/choose_occasion`}
            path={`private-theaters-in-${path}/:location/booking_overview/:theaterCode/choose_occasion`}
            element={<Occasion />}
          />
        ))}
        {["bangalore", "hyderabad", "delhi"].map(path => (
          <Route
            key={`mweb-private-theaters-in-${path}/:location/booking_overview/:theaterCode/choose_occasion`}
            path={`mweb-private-theaters-in-${path}/:location/booking_overview/:theaterCode/choose_occasion`}
            element={<Occasion />}
          />
        ))}
        
        {["bangalore", "hyderabad", "delhi"].map(path => (
          <Route
            key={`private-theaters-in-${path}/:location/booking_overview/:theaterCode/choose_occasion/choose_cake`}
            path={`private-theaters-in-${path}/:location/booking_overview/:theaterCode/choose_occasion/choose_cake`}
            element={<CakeSelection />}
          />
        ))}
        {["bangalore", "hyderabad", "delhi"].map(path => (
          <Route
            key={`mweb-private-theaters-in-${path}/:location/booking_overview/:theaterCode/choose_occasion/choose_cake`}
            path={`mweb-private-theaters-in-${path}/:location/booking_overview/:theaterCode/choose_occasion/choose_cake`}
            element={<CakeSelection />}
          />
        ))}

        {["bangalore", "hyderabad", "delhi"].map(path => (
          <Route
            key={`private-theaters-in-${path}/:location/booking_overview/:theaterCode/choose_occasion/choose_cake/choose_decoration`}
            path={`private-theaters-in-${path}/:location/booking_overview/:theaterCode/choose_occasion/choose_cake/choose_decoration`}
            element={<DecorationSelection />}
          />
        ))}
        {["bangalore", "hyderabad", "delhi"].map(path => (
          <Route
            key={`mweb-private-theaters-in-${path}/:location/booking_overview/:theaterCode/choose_occasion/choose_cake/choose_decoration`}
            path={`mweb-private-theaters-in-${path}/:location/booking_overview/:theaterCode/choose_occasion/choose_cake/choose_decoration`}
            element={<DecorationSelection />}
          />
        ))}

        {["bangalore", "hyderabad", "delhi"].map(path => (
          <Route
            key={`private-theaters-in-${path}/`}
            path={`private-theaters-in-${path}/`}
            element={<TheaterDetails/>}
          />
        ))}
        {["bangalore", "hyderabad", "delhi"].map(path => (
          <Route
            key={`private-movie=theaters-${path}/`}
            path={`private-movie-theaters-${path}/`}
            element={<Navigate to={`/private-theaters-in-${path}`} />}
          />
        ))}
        <Route path="*" element={<PageNotFound />} />

      </Routes>
      </Layout>
    </>
  );
}

export default App;
