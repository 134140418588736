import Header from "../../../../../Home/screens/Header/Header";
import CopyRight from "../../../../../Home/screens/CopyRight/CopyRight";
import { HiArrowLeft } from "react-icons/hi";

import { DecorationList } from "./DecorationList";
import { GiftsList } from "./GiftsList";
import { SpecialServicesList } from "./SpecialServices";
import BookingSummary from "../BookingSummery/BookingSummary";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Tick from "../../../../../../assets/images/Tick.png";

import {
  getActiveDecorations,
  getActiveGifts,
  getActiveServices,
  getBookingSummary,
  getCouponInfo,
  getFogCount,
  getIsCouponAppliedSuccessfully,
  getSelectedTheater,
  getSubTotalAmount,
} from "../../../../../../redux/selectors/bookingSelector";
import { useEffect, useState } from "react";
import {
  setActiveDecorations,
  setActiveGifts,
  setActiveServices,
  setBookingSummaryAddons,
  setFogCount,
  setSubtotalAmount,
} from "../../../../../../redux/actions/bookingActions";
import * as S from "./DecorationSelection.style";
import { getUpdateBookingDetail } from "../../../../../../redux/selectors/blogsSelector";
import {store} from "../../../../../../store";
import FogEntryCount from "./ForEntryCount";

const DecorationSelection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedTheater = useSelector(getSelectedTheater);
  const activeDecorationsSelector = useSelector(getActiveDecorations);
  const activeGiftsSelector = useSelector(getActiveGifts);
  const activeServicesSelector = useSelector(getActiveServices);

  const [activeDecoration, setActiveDecoration] = useState<String[]>(
    activeDecorationsSelector || []
  );
  const [activeGift, setActiveGift] = useState<String[]>(
    activeGiftsSelector || []
  );
  const [activeService, setActiveService] = useState<String[]>(
    activeServicesSelector || []
  );

  const [addonsList, setAddonsList] = useState([]);
  const [showBookingSummary, setShowBookingSummary] = useState<Boolean>(false);

  const addOns = selectedTheater?.addOns || {};
  const bookingSummarySelectorData = useSelector(getBookingSummary);
  const { subTotal, advancePayment } = bookingSummarySelectorData || {};
  const { gift: gifts, decor, special: SpecialServices } = addOns;
  const [bookingSummary, setBookingSummary] = useState<any>(
    bookingSummarySelectorData.addOns || []
  );
  const isCouponApplied = useSelector(getIsCouponAppliedSuccessfully);
  const subTotalAmount = useSelector(getSubTotalAmount) || 0;
 // const fogCount = useSelector(getFogCount);
  const coupon = useSelector(getCouponInfo);
  const { code: couponCode, price: couponPrice } = coupon || {};

  const updateBookingDetail = useSelector(getUpdateBookingDetail) || {};
  const isUpdateBooking = Object.keys(updateBookingDetail).length > 0;
  useEffect(() => {
    const addonsList =
      updateBookingDetail?.addOns?.split(",")?.map((addOn: any) => {
        if(addOn.includes("Photoshoot") || addOn.includes("Fog Entry") || addOn.includes("Bouquet")
          || addOn.includes("Chocolate") ){
          const secondParenIndex = addOn.indexOf('(', addOn.indexOf('(') + 1);
          return addOn.substring(0, secondParenIndex).trim();
        }
        return addOn?.split?.("(")?.[0]?.trim();
      }) || [];
    setAddonsList(addonsList);
    if (bookingSummary && bookingSummary.length == 0) {
      const filteredDecoration = [
        ...decor,
        ...gifts,
        ...SpecialServices,
      ]?.filter(
        (eachDecor: {
          name: String;
          price: number;
          iconUrl: string;
          index: number;
        }) => addonsList?.includes(eachDecor.name)
      );
      setBookingSummary([...filteredDecoration]);
    }
  }, [updateBookingDetail.addOns]);

  useEffect(() => {
    const cakeNames = updateBookingDetail?.addOns
      ?.split(",")
      ?.map((cake: any) => {
        if(cake.includes("Photoshoot")|| cake.includes("Fog Entry") || cake.includes("Bouquet")
        || cake.includes("Chocolate")){
          const secondParenIndex = cake.indexOf('(', cake.indexOf('(') + 1);
          return cake.substring(0, secondParenIndex).trim();
        }
        return cake?.split("(")[0]?.trim();
      });
    console.log("update flow addons", cakeNames);
    dispatch(setActiveDecorations(cakeNames));
    dispatch(setActiveGifts(cakeNames));
    dispatch(setActiveServices(cakeNames));
    //dispatch(setFogCount(updateBookingDetail?.fogCount || 0))
  }, [updateBookingDetail.addOns]);

  const handleNext = () => {
    if (isUpdateBooking)
      navigate(`/terms_conditions_agreement/${updateBookingDetail?.id}`);
    else navigate("/terms_conditions_agreement");
  };

  const handleSelectDecoration = (decoration: {
    name: String;
    price: number;
  }) => {
    if (activeDecoration?.includes(decoration?.name)) {
      let removeDecoration = activeDecoration?.filter(
        (activeDecor: String) => activeDecor !== decoration?.name
      );
      setActiveDecoration([...removeDecoration]);

      const filteredDecoration = bookingSummary?.filter(
        (eachDecoration: { name: String; price: number }) =>
          eachDecoration?.name !== decoration?.name
      );

      setBookingSummary([...filteredDecoration]);
    } else {
      setActiveDecoration([...activeDecoration, decoration.name]);
      setBookingSummary([...bookingSummary, decoration]);
    }
  };

  const handleSelectGift = (gift: { name: String; price: number }) => {
    if (activeGift?.includes(gift?.name)) {
      let removeGifts = activeGift?.filter(
        (activeGift: String) => activeGift !== gift.name
      );
      setActiveGift([...removeGifts]);

      const filteredGifts = bookingSummary?.filter(
        (activeGift: { name: String; price: number }) =>
          activeGift.name !== gift.name
      );
      setBookingSummary([...filteredGifts]);
    } else {
      setActiveGift([...activeGift, gift.name]);
      setBookingSummary([...bookingSummary, gift]);
    }
  };


  const handleSelectService = (service: { name: String; price: number }, event:any) => {
    if (activeService.includes(service.name)) {
      let removeServices = activeService.filter(
        (activeService: String) => activeService !== service.name
      );
      setActiveService([...removeServices]);

      const filteredServices = bookingSummary?.filter(
        (activeService: { name: String; price: number }) =>
          activeService.name !== service.name
      );
      setBookingSummary([...filteredServices]);
    } else {
      setActiveService([...activeService, service.name]);
      setBookingSummary([...bookingSummary, service]);
      //store.dispatch(setSubtotalAmount());
        }
  };

  const handleShowBookingSummary = () => {
    setShowBookingSummary(!showBookingSummary);
  };

  useEffect(() => {
    if (bookingSummary) {
      // Create new arrays to hold updated state values
      const newActiveDecoration = [...activeDecoration];
      const newActiveGift = [...activeGift];
      const newActiveService = [...activeService];
  
      bookingSummary.forEach((summary: any) => {
        if (!newActiveDecoration.includes(summary.name)) {
          newActiveDecoration.push(summary.name);
        }
        if (!newActiveGift.includes(summary.name)) {
          newActiveGift.push(summary.name);
        }
        if (!newActiveService.includes(summary.name)) {
          newActiveService.push(summary.name);
        }
      });  

      setActiveDecoration(newActiveDecoration);
      setActiveGift(newActiveGift);
      setActiveService(newActiveService);
    }
    // Dispatch actions
    dispatch(setBookingSummaryAddons(bookingSummary));
    store.dispatch(setSubtotalAmount());
  }, [bookingSummary]);

  useEffect(() => {
    dispatch(setActiveDecorations(activeDecoration));
  }, [activeDecoration]);

  useEffect(() => {
    dispatch(setActiveGifts(activeGift));
  }, [activeGift]);

  useEffect(() => {
    dispatch(setActiveServices(activeService));
  }, [activeService]);
  const handlefogEntryCount = () => {
  };
  return (
    <>
      <Header />
      <S.BookingOverviewContainer>
        <S.LeftViewSectionContainer>
          <S.BackButtonContainer onClick={() => navigate(-1)}>
            <HiArrowLeft size={24} />
            <S.BackButtonTxt>Back</S.BackButtonTxt>
          </S.BackButtonContainer>

          <S.Overview>
            <S.HeadingContainer>
              <S.HeadingTxt>Extra Decoration</S.HeadingTxt>
              <S.OptionalContainer>(optional)</S.OptionalContainer>
            </S.HeadingContainer>

            <S.CakeView>
              {decor &&
                decor.length > 0 &&
                decor.map((decoration: any) => {
                  return (
                    <S.OccasionView
                      key={decoration.name}
                      onClick={() => handleSelectDecoration(decoration)}
                    >
                      {activeDecoration.includes(decoration.name) ? (
                        <S.ImgContainer>
                          <S.TickImageContainer src={Tick} alt="tick" />
                          <img
                            src={decoration?.iconUrl}
                            alt={decoration.name}
                            title={decoration.name}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              overflow: "hidden",
                              top: "-3.3rem",
                              position: "relative",
                            }}
                          />
                        </S.ImgContainer>
                      ) : (
                        <S.ImgContainer>
                          <img
                            src={decoration?.iconUrl}
                            alt={decoration.name}
                            title={decoration.name}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              overflow: "hidden",
                            }}
                          />
                        </S.ImgContainer>
                      )}
                      <S.OccasionTitle>{decoration.name}</S.OccasionTitle>
                      <S.OccasionPrice>
                        &#8377; {decoration.price}
                      </S.OccasionPrice>
                    </S.OccasionView>
                  );
                })}
            </S.CakeView>
          </S.Overview>
          <S.Overview>
            <S.HeadingContainer>
              <S.HeadingTxt>Choose Gifts </S.HeadingTxt>
              <S.OptionalContainer>(optional)</S.OptionalContainer>
            </S.HeadingContainer>

            <S.CakeView>
              {gifts &&
                gifts.length > 0 &&
                gifts.map((gift: any) => {
                  return (
                    <S.OccasionView
                      key={gift.name}
                      onClick={() => handleSelectGift(gift)}
                    >
                      {activeGift.includes(gift.name) ? (
                        <S.ImgContainer>
                          <S.TickImageContainer src={Tick} alt="tick" />
                          <img
                            src={gift?.iconUrl}
                            alt={gift?.name}
                            title={gift?.name}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              overflow: "hidden",
                              top: "-3.3rem",
                              position: "relative",
                            }}
                          />
                        </S.ImgContainer>
                      ) : (
                        <>
                          <S.ImgContainer>
                            <img
                              src={gift?.iconUrl}
                              alt={gift?.name}
                              title={gift?.name}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                overflow: "hidden",
                              }}
                            />
                          </S.ImgContainer>
                        </>
                      )}
                      <S.OccasionTitle>{gift?.name}</S.OccasionTitle>
                      <S.OccasionPrice>
                      &#8377;{gift?.price}
                      {gift?.oldPrice != null && (
                        <span style={{ textDecoration: 'line-through' }}>
                            &#8377;{gift.oldPrice}
                        </span>
                      )}
                      </S.OccasionPrice>
                    </S.OccasionView>
                  );
                })}
            </S.CakeView>
          </S.Overview>

           <S.Overview>
            <S.HeadingContainer>
              <S.HeadingTxt>Special services </S.HeadingTxt>
              <S.OptionalContainer>(optional)</S.OptionalContainer>
            </S.HeadingContainer>

            <S.CakeView>
              {SpecialServices &&
                SpecialServices.length > 0 &&
                SpecialServices.map((service: any) => {
                  return (
                    <S.OccasionView
                      key={service.name}
                      style={{ display: 'flex', flexDirection: 'column', alignItems: 'inherit', height: '100%' }}
                      onClick={(e) => handleSelectService(service, e)}
                    >
                      {activeService.includes(service.name) ? (
                        <S.ImgContainer>
                          <S.TickImageContainer src={Tick} alt="tick" />
                          <img
                            src={service?.iconUrl}
                            alt={service?.name}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              overflow: "hidden",
                              top: "-3.3rem",
                              position: "relative",
                              borderRadius: "50%",
                            }}
                          />
                        </S.ImgContainer>
                      ) : (
                        <>
                          <S.ImgContainer>
                            <img
                              src={service?.iconUrl}
                              alt={service?.name}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                overflow: "hidden",
                                borderRadius: "50%",
                              }}
                            />
                          </S.ImgContainer>
                        </>
                      )}
                       {service?.name.includes('(') ? 
                       <div style={{alignSelf: 'normal'}}>
                       <S.OccasionTitle >{service?.name.split('(')[0]}</S.OccasionTitle>
                       <S.OccasionTitle>{"("+service?.name.split('(')[1]}</S.OccasionTitle>
                       </div>
                       : <S.OccasionTitle >{service?.name}</S.OccasionTitle>}
                      <S.OccasionPrice >
                        &#8377;{service?.price} 
                        {service?.oldPrice != null && (
                          <span style={{ textDecoration: 'line-through', paddingLeft: '5px' }}>
                                 &#8377;{service.oldPrice}
                          </span>
                        )}
                        </S.OccasionPrice>
                    </S.OccasionView>
                  );
                })}
            </S.CakeView>
          </S.Overview> 

          <S.Instruction>
            <S.PleaseNoteHeading>Please note: </S.PleaseNoteHeading>
            <S.PleaseNoteTxt>
            <ul>
              <li>{`Based on theater you have selected, we recommend taking ${selectedTheater.maxPerson > 8 ? 4 : selectedTheater.maxPerson > 4 ? 3 : 2 } fog entries for best experience.`}</li>
              {/* <li>We have an in-house kitchen that serves food at affordable prices inside the theater.</li> */}
            </ul>
            </S.PleaseNoteTxt>
          </S.Instruction>
          <S.MwebFooterContainer>
            <S.PriceBreakupSummaryContainer>
              <S.PriceBreakupHeader>
                <S.PriceBreakupTotal>Total</S.PriceBreakupTotal>
                <S.PriceBreakupPrice>
                  &#8377;{" "}
                  {isCouponApplied
                    ? subTotalAmount - couponPrice
                    : subTotalAmount}
                </S.PriceBreakupPrice>
              </S.PriceBreakupHeader>
              <S.PriceBreakup>
                <S.BookingSummaryHeading onClick={handleShowBookingSummary}>
                  <S.BookingSummaryTxt> Booking summary</S.BookingSummaryTxt>
                  {showBookingSummary ? (
                    <MdOutlineKeyboardArrowUp size={24} />
                  ) : (
                    <MdOutlineKeyboardArrowDown size={24} />
                  )}
                </S.BookingSummaryHeading>
              </S.PriceBreakup>
              <S.PriceBreakupDetails>
                {showBookingSummary ? <BookingSummary /> : null}
              </S.PriceBreakupDetails>
            </S.PriceBreakupSummaryContainer>
            <S.BookNowBtn
              onClick={handleNext}
              type="submit"
              // disabled={!isFormValid}
            >
              Next step
            </S.BookNowBtn>
          </S.MwebFooterContainer>
        </S.LeftViewSectionContainer>
        <S.RightViewSectionContainer>
          <BookingSummary fromPage="decoration" />
        </S.RightViewSectionContainer>
      </S.BookingOverviewContainer>
      <CopyRight />
    </>
  );
};

export default DecorationSelection;
